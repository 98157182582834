import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import signupCover from '@/public/images/covers/signup-cover.webp';
import googleIcon from '@/public/images/icons/google.svg';
import Header from '@/layout/header/Header';
import { safeLocalStorage } from '@/core/storage/safeLocalStorage';
import { useRouter } from 'next/router';
import { useRecoilState, useRecoilValue } from 'recoil';
import useAuthenticationMethods from '@/features/account/authentication/useAuthenticationMethods';
import * as Sentry from '@sentry/nextjs';
import { authState } from '@/stores/authStore';
import { creditsRedirectState } from '@/stores/paymentStore';

const _USER_VISIT_KEY = 'isOldUser';

function HomePage() {
  const router = useRouter();
  const isLoggedIn = useRecoilValue(authState);
  const [shouldRedirectToCredits, setShouldRedirectToCredits] =
    useRecoilState(creditsRedirectState);
  const { performLogin } = useAuthenticationMethods();

  const isOldUser = safeLocalStorage.getItem(_USER_VISIT_KEY);
  const [isLoading, setIsLoading] = useState(false);
  const [hasManuallyLoggedIn, setHasManuallyLoggedIn] = useState(false);

  useEffect(() => {
    if (isOldUser !== 'true') {
      safeLocalStorage.setItem(_USER_VISIT_KEY, 'true');
    }
  }, [isOldUser]);

  const onLoginSuccess = useCallback(async () => {
    const pathname = shouldRedirectToCredits
      ? '/credits'
      : isOldUser === 'true'
      ? '/balance'
      : '/key';

    if (shouldRedirectToCredits) {
      setShouldRedirectToCredits(false);
    }

    await router.push({ pathname });
  }, [isOldUser, router, setShouldRedirectToCredits, shouldRedirectToCredits]);

  useEffect(() => {
    if (isLoggedIn && !hasManuallyLoggedIn) {
      onLoginSuccess().catch(console.error);
    }
  }, [hasManuallyLoggedIn, isLoggedIn, onLoginSuccess, router]);

  return isLoggedIn ? null : (
    <div className="bg-gray-450 flex rounded-2xl min-h-[612px] max-w-[796px]">
      <div className="relative w-1/2 text-white flex">
        <img
          {...signupCover}
          className="absolute inset-0 w-full h-full object-fill"
          alt=""
        />
      </div>
      <div className="w-1/2 flex flex-col justify-center px-12 relative">
        <p className="SB25 text-white">Login / Sign Up</p>
        <p className="M18 text-overlay-white-40 mt-2">
          Enter or create your account to view the balance of your API credits
          and purchase new ones.
        </p>
        <button
          type="button"
          className="btn btn--large btn--social my-10"
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);

            try {
              await performLogin();

              setHasManuallyLoggedIn(true);
              await onLoginSuccess();
            } catch (e) {
              Sentry.captureException(e);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          <img {...googleIcon} alt="" />
          <span className="ml-1 text-center">
            {isLoading ? 'Logging in...' : 'Continue with Google'}
          </span>
        </button>
        <p className="M12 text-overlay-white-40 text-center">
          By continuing, you accept our{' '}
          <a
            className="underline"
            href="/docs/terms-of-service.pdf"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Terms of Service
          </a>{' '}
          and acknowledge receipt of our{' '}
          <a
            className="underline"
            href="/docs/privacy-policy.pdf"
            target="_blank"
            rel="noreferrer nofollow"
          >
            Privacy & Cookie Policy
          </a>
        </p>
      </div>
    </div>
  );
}

HomePage.getLayout = function getLayout(
  page: ReactElement,
  isLoggedIn: boolean
) {
  return (
    <>
      <Header />
      <div
        className={`w-full h-full flex items-center justify-center ${
          isLoggedIn ? 'hidden' : ''
        }`}
      >
        {page}
      </div>
    </>
  );
};

export default HomePage;
