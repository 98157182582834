import { useCallback } from 'react';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithPopup,
  signOut,
} from 'firebase/auth';
import { api, GenericAxiosError } from '@/core/http';
import { AxiosResponse } from 'axios';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { authState } from '@/stores/authStore';
import { useRouter } from 'next/router';
import { User, userState } from '@/stores/userStore';
import { creditsRedirectState } from '@/stores/paymentStore';

const useAuthenticationMethods = () => {
  const router = useRouter();
  const setIsLoggedIn = useSetRecoilState(authState);
  const resetIsLoggedIn = useResetRecoilState(authState);

  const setUser = useSetRecoilState(userState);
  const resetUser = useResetRecoilState(userState);
  const resetCreditsRedirect = useResetRecoilState(creditsRedirectState);

  const performLogin = useCallback(async () => {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    await signInWithPopup(auth, provider);
    if (!auth.currentUser) {
      return;
    }

    const idToken = await auth.currentUser.getIdToken(true);
    const result: AxiosResponse<User, GenericAxiosError> = await api.post(
      '/admin/',
      {},
      {
        headers: { Authorization: `Bearer ${idToken}` },
      }
    );

    setUser(result.data);
    setIsLoggedIn(true);
  }, [setIsLoggedIn, setUser]);

  const performLogout = useCallback(async () => {
    const auth = getAuth();
    await signOut(auth);

    await api.post('/admin/logout', {});

    resetIsLoggedIn();
    resetUser();
    resetCreditsRedirect();
    await router.push({ pathname: '/' });
  }, [resetCreditsRedirect, resetIsLoggedIn, resetUser, router]);

  return {
    performLogin,
    performLogout,
  };
};

export default useAuthenticationMethods;
